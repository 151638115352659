import React, { createContext, useState } from 'react';
import {
  getParceiros, getClientes, getPeriodos,
  getAgentes, postCamadas, postCamadasNegocio,
} from 'services/filters';
import {
  postICRRelacoes, postICRRelacoesCamadas,
  postProminientSupplication, postCloudData,
  postRespondentList, postICREvolucao,
  postICREvolucaoSuplicas, postDadosAgora,
  postPosicionamentoRelacional, postPainelSuplicas,
  postFrasesPalavrasRespondente
} from 'services/relativeIndicator';
import { getUserData } from 'services/getUserData';

export const RelativeIndicatorContext = createContext();
export const RelativeIndicatorProvider = ({ children }) => {
  const [partners, setPartners] = useState({ loading: true });
  const [clients, setClients] = useState({ loading: true });
  const [periods, setPeriods] = useState({ loading: true });
  const [agents, setAgents] = useState({ loading: true });
  const [camadas, setCamadas] = useState({ loading: true });
  const [camadasNegocio, setCamadasNegocio] = useState({ loading: true });
  const [dadosAgora, setDadosAgora] = useState({ loading: true });
  const [icr, setIcr] = useState({ loading: false });
  const [icrCamadas, setIcrCamadas] = useState({ loading: false });
  const [prominientSupplication, setProminientSupplication] = useState({ loading: false });
  const [cloudData, setCloudData] = useState({ loading: false });
  const [respondentList, setRespondentList] = useState({ loading: true });
  const [posicionamentoRelacional, setPosicionamentoRelacional] = useState({ loading: false });
  const [painelSuplicas, setPainelSuplicas] = useState({ loading: false });
  const [frasesPalavrasRespondente, setFrasesPalavrasRespondente] = useState({ loading: true });
  const [ICREvolucao, setICREvolucao] = useState({ loading: false });
  const [userData, setUserData] = useState(null);
  const [partnerID, setPartnerID] = useState(null);
  const [clientID, setClientID] = useState(null);
  const [periodID, setPeriodID] = useState(null);

  async function getPartners() {
    setPartners({ loading: true });

    const response = await getParceiros();

    if (response.success) {
      setPartners(response.data);
    } else {
      setPartners(response);
    }
  };

  async function getClients(data, callBack) {
    setClients({ loading: true });

    const response = await getClientes(data);

    if (response.error) {
      if (response && !response.success) {
        setClients(response);
      }
    } else {
      setClients(response.data);
      if (callBack) {
        callBack();
      }
    }
  };

  async function getPeriods(data, callBack) {
    setPeriods({ loading: true });

    const response = await getPeriodos(data);

    if (response.error) {
      if (response && !response.success) {
        setPeriods(response);
      }
    } else {
      setPeriods(response.data);
      if (callBack) {
        callBack();
      }
    }
  };

  async function getAgents(data) {
    setAgents({ loading: true });

    const response = await getAgentes(data);

    if (response.error) {
      if (response && !response.success) {
        setAgents(response);
      }
    } else {
      setAgents(response.data);
    }
  };

  async function getICR(data) {
    setIcr({ loading: true });

    const response = await postICRRelacoes(data);

    if (response.error) {
      if (response && !response.success) {
        setIcr(response);
      }
    } else {
      setIcr(response.data);
    }
  };

  async function getICRCamadas(data) {
    setIcrCamadas({ loading: true });

    const response = await postICRRelacoesCamadas(data);

    if (response.error) {
      if (response && !response.success) {
        setIcrCamadas(response);
      }
    } else {
      setIcrCamadas(response.data);
    }
  };

  async function getProminientSupplication(data) {
    setProminientSupplication({ loading: true });

    const response = await postProminientSupplication(data);

    if (response.error) {
      if (response && !response.success) {
        setProminientSupplication(response);
      }
    } else {
      setProminientSupplication(response.data);
    }
  };

  async function getCloudData(data) {
    setCloudData({ loading: true });

    const response = await postCloudData(data);

    if (response.error) {
      if (response && !response.success) {
        setCloudData(response);
      }
    } else {
      setCloudData(response.data);
    }
  };

  async function getRespondentList(data) {
    setRespondentList({ loading: true });

    const response = await postRespondentList(data);

    if (response.error) {
      if (response && !response.success) {
        setRespondentList(response);
      }
    } else {
      setRespondentList(response.data);
    }
  };

  async function getPosicionamentoRelacional(data) {
    setPosicionamentoRelacional({ loading: true });

    const response = await postPosicionamentoRelacional(data);

    if (response.error) {
      if (response && !response.success) {
        setPosicionamentoRelacional(response);
      }
    } else {
      setPosicionamentoRelacional(response.data);
    }
  };

  async function getPainelSuplicas(data) {
    setPainelSuplicas({ loading: true });

    const response = await postPainelSuplicas(data);

    if (response.error) {
      if (response && !response.success) {
        setPainelSuplicas(response);
      }
    } else {
      setPainelSuplicas({data: response.data, header: response.header});
    }
  };

  async function getFrasesPalavrasRespondente(data) {
    setFrasesPalavrasRespondente({ loading: true });

    const response = await postFrasesPalavrasRespondente(data);

    if (response.error) {
      if (response && !response.success) {
        setFrasesPalavrasRespondente(response);
      }
    } else {
      setFrasesPalavrasRespondente(response.data);
    }
  };

  async function getCamadas(data) {
    setCamadas({ loading: true });

    const response = await postCamadas(data);

    if (response.error) {
      if (response && !response.success) {
        setCamadas(response);
      }
    } else {
      setCamadas(response.data);
    }
  };

  async function getCamadasNegocio(data) {
    setCamadasNegocio({ loading: true });

    const response = await postCamadasNegocio(data);

    if (response.error) {
      if (response && !response.success) {
        setCamadasNegocio(response);
      }
    } else {
      setCamadasNegocio(response.data);
    }
  };

  async function getICREvolucao(data) {
    setICREvolucao({ loading: true });
    const postService = data.tipoPesquisa === 'S' ? postICREvolucaoSuplicas : postICREvolucao;

    const response = await postService(data);

    if (response.error) {
      if (response && !response.success) {
        setICREvolucao(response);
      }
    } else {
      setICREvolucao(response.data);
    }
  };

  async function getDadosAgora(data) {
    setDadosAgora({ loading: true });

    const response = await postDadosAgora(data);

    if (response.error) {
      if (response && !response.success) {
        setDadosAgora(response);
      }
    } else {
      setDadosAgora(response.data);
    }
  };

  async function clearFilters() {
    setPartners({ loading: true })
    setClients({ loading: true })
    setPeriods({ loading: true })
    setAgents({ loading: true })
    setCamadas({ loading: true })
    setCamadasNegocio({ loading: true })

    setDadosAgora({ loading: true })
    setIcr({ loading: true })
    setIcrCamadas({ loading: true })
    setProminientSupplication({ loading: true })
    setCloudData({ loading: true })
    setRespondentList({ loading: true })
    setICREvolucao({ loading: true })
    setPosicionamentoRelacional({ loading: true })
    setPainelSuplicas({ loading: true })
    setFrasesPalavrasRespondente({ loading: true })
  };

  async function getFilterUserData() {
    setUserData(getUserData());
  }

  async function setFilterPartnerID(newId) {
    setPartnerID(newId);
  }

  async function setFilterClientID(newId) {
    setClientID(newId);
  }

  async function setFilterPeriodID(newId) {
    setPeriodID(newId);
  }

  return (
    <RelativeIndicatorContext.Provider value={{
      partners, getPartners,
      clients, getClients,
      periods, getPeriods,
      agents, getAgents,
      camadas, getCamadas,
      camadasNegocio, getCamadasNegocio,
      icr, getICR,
      icrCamadas, getICRCamadas,
      prominientSupplication, getProminientSupplication,
      cloudData, getCloudData,
      respondentList, getRespondentList,
      posicionamentoRelacional, getPosicionamentoRelacional,
      frasesPalavrasRespondente, getFrasesPalavrasRespondente,
      painelSuplicas, getPainelSuplicas,
      ICREvolucao, getICREvolucao,
      dadosAgora, getDadosAgora,
      clearFilters, getFilterUserData, userData,
      partnerID,  setFilterPartnerID,
      clientID,  setFilterClientID,
      periodID,  setFilterPeriodID
    }}>
      {children}
    </RelativeIndicatorContext.Provider>
  );
};
