import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { getUserData } from "services/getUserData";
import Select from "react-select";
import { RelativeIndicatorContext } from "context/RelativeIndicator";

const FilterWrapper = styled.div`
  width: 100%;
  > div {
    margin-bottom: 24px;
  }
`;

const FilterTitle = styled.p`
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const Button = styled.button`
  background: rgba(2, 255, 103, 0.4);
  border-radius: 5px;
  color: #0f355f;
  padding: 18px 64px;
  font-weight: bold;
  border: 0;

  :hover {
    opacity: 0.8;
  }
`;

function Filters({ onSearch }) {
  const {
    partners,
    getPartners,
    clients,
    getClients,
    agents,
    getAgents,
    partnerID,
    setFilterPartnerID,
    clientID,
    setFilterClientID,
    agentID,
    setFilterAgentID,
    searchType,
    setFilterSearchType,
  } = useContext(RelativeIndicatorContext);

  console.log("Filters.js");

  const [filters, setFilters] = useState({
    partner: partnerID || null,
    client: clientID || null,
    agent: agentID || null,
    searchType: searchType || { label: "Relação", value: "R" },
  });

  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState({
    clients: false,
    agents: false,
  });

  useEffect(() => {
    const initializeData = async () => {
      const userDataResult = getUserData();
      setUserData(userDataResult);

      if (!userDataResult.idPartner && !partnerID) {
        await getPartners();
      } else if (userDataResult.idPartner) {
        const selectedPartner = partners.find(
          (p) => p.value === userDataResult.idPartner
        );
        if (selectedPartner) {
          setFilters((prev) => ({ ...prev, partner: selectedPartner }));
          setFilterPartnerID(selectedPartner);
        }
      }
    };

    initializeData();
  }, []);

  useEffect(() => {
    if (filters.partner) {
      setIsLoading((prev) => ({ ...prev, clients: true }));
      getClients({ idParceiro: filters.partner.value })
        .then(() => {
          if (!filters.client) {
            const selectedClient = clients.find((c) => c.value === clientID) || clients[0];
            setFilters((prev) => ({ ...prev, client: selectedClient }));
            setFilterClientID(selectedClient);
          }
        })
        .finally(() => {
          setIsLoading((prev) => ({ ...prev, clients: false }));
        });
    }
  }, [filters.partner]);

  useEffect(() => {
    if (filters.client) {
      setIsLoading((prev) => ({ ...prev, agents: true }));
      getAgents({
        idParceiro: filters.partner.value,
        idCliente: filters.client.value,
      }).finally(() => {
        setIsLoading((prev) => ({ ...prev, agents: false }));
      });
    }
  }, [filters.client]);

  const handlePartnerChange = (selected) => {
    setFilters((prev) => ({
      ...prev,
      partner: selected,
      client: null,
      agent: null,
    }));
    setFilterPartnerID(selected);
    setFilterClientID(null);
    setFilterAgentID(null);
  };

  const handleClientChange = (selected) => {
    setFilters((prev) => ({
      ...prev,
      client: selected,
      agent: null,
    }));
    setFilterClientID(selected);
    setFilterAgentID(null);
  };

  const handleSearchTypeChange = (selected) => {
    setFilters((prev) => ({ ...prev, searchType: selected }));
    setFilterSearchType(selected);
  };

  const handleAgentChange = (selected) => {
    setFilters((prev) => ({ ...prev, agent: selected }));
    setFilterAgentID(selected);
  };

  const handleSearchButtonClick = () => {
    const idsAgentesHolograma = filters.agent?.length
      ? filters.agent.map((item) => item.value).join("|;") + "|;"
      : "";

    onSearch({
      idParceiro: filters.partner?.value,
      idCliente: filters.client?.value,
      nomeCliente: filters.client?.label,
      idsAgentesHolograma,
      tipoPesquisa: filters.searchType.value,
    });
  };

  return (
    <FilterWrapper>
      <FilterTitle>Selecione suas opções de filtro:</FilterTitle>

      <Select
        placeholder="Parceiro"
        value={filters.partner}
        options={partners || []}
        classNamePrefix="select2-selection"
        onChange={handlePartnerChange}
        isDisabled={isLoading.clients}
      />

      <Select
        placeholder="Cliente"
        value={filters.client}
        options={clients || []}
        classNamePrefix="select2-selection"
        onChange={handleClientChange}
        isDisabled={isLoading.clients || !filters.partner}
      />

      <Select
        placeholder="Tipo de pesquisa"
        value={filters.searchType}
        options={[
          { label: "Relação", value: "R" },
          { label: "Suplicas", value: "S" },
        ]}
        classNamePrefix="select2-selection"
        onChange={handleSearchTypeChange}
      />

      {filters.searchType.value === "S" && (
        <Select
          placeholder="Todas as relações"
          value={filters.agent}
          options={agents || []}
          classNamePrefix="select2-selection"
          isMulti
          isDisabled={isLoading.agents || !filters.client}
          onChange={handleAgentChange}
        />
      )}

      <ButtonWrapper>
        <Button
          onClick={handleSearchButtonClick}
          disabled={isLoading.clients || isLoading.agents}
        >
          Aplicar filtro
        </Button>
      </ButtonWrapper>
    </FilterWrapper>
  );
}

export default Filters;